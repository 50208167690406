import sal from 'sal.js';
import simpleParallax from 'simple-parallax-js';

sal();

const parallaxLvl1 = document.getElementsByClassName('parallax-lvl1');
new simpleParallax(parallaxLvl1, {
    scale: 1.25,
    delay: .6,
    overflow: true
});

const parallaxLvl2 = document.getElementsByClassName('parallax-lvl2');
new simpleParallax(parallaxLvl2, {
    scale: 1.5,
    delay: .5,
    overflow: true
});

window.onscroll = function() {shrinkLogo()};

function shrinkLogo() {
    let logo = document.getElementById("logo");

    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        logo.classList.add('small');
    } else {
        logo.classList.remove('small');
    }
}